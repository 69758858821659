<template>
  <div class="page">
    <div class="page-title">
      <img @click="$router.push('/')" src="@/assets/images/back.svg">
      {{$route.meta.routeTitle}}
    </div>
    <div class="par">Накопленные вторресурсы можно сдать в пункты приема вторичного сырья.</div>
    <h4>Что относится к вторичным ресурсам?</h4>
    <div class="par">
      Картон и бумага, газеты и журналы, стрейч-плёнка, пэт бутылки, пакеты, тара из-под бытовой химии, пустые канистры из-под машинных масел, ящики из-под овощей, ПВХ (стеновые панели и подоконники), тазы, ведра, майонезные ведра, подкрылки, бампера и трубы.
    </div>
    <h4>Вторичный ресурсы принимаются:</h4>
    <ul>
      <li>
        <img src="@/assets/images/point-mini.svg">
        <span>Стерлитамак: ИП Бутырин, Элеваторная 2а</span>
      </li>
      <li>
        <img src="@/assets/images/point-mini.svg">
        <span>Ишимбайский район: ООО ЭКО-Сити, Урман-Бишкадарское сельское поселение, 5 км автодороги Ишимбай-Красноусольск</span>
      </li>
      <li>
        <img src="@/assets/images/point-mini.svg">
        <span>Салават: ИП Самохин, Нуриманова, 24</span>
      </li>
    </ul>
    <h4>Важно</h4>

    <div class="par">
      Стоимость приема вторичного сырья меняется в зависимости от спроса. Актуальную стоимость вы можете уточнить по телефону диспетчера:
    </div>

    <a href="tel:+79174289157" class="phone">
      <img src="@/assets/images/phone-green.svg" alt="">
      <span>+7 (917) 428 91 57</span>
    </a>
  </div>
</template>

<script setup>

</script>

<style scoped lang="scss">
  h3{
    font-size: 24px;
    line-height: 29px;
    font-weight: 500;
    margin-bottom: 25px;
  }
  h4{
    margin-bottom: 18px;
  }
  .par, ul, a{
    margin-bottom: 36px;
    font-size: 16px;
    line-height: 28px;
    font-family: "TT Norms Pro Regular";

    a{
      color: $ec-green;
    }

    li{
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      span{
        font-family: "TT Norms Pro Regular";
        font-size: 16px;
        line-height: 22px;
      }
      img{
        margin-right: 10px;
      }
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .phone{
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-top: -30px;
    span{
      font-size: 16px;
      line-height: 28px;
      color: #20B12A;
      margin-left: 10px;
    }
  }

.page-title{
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-left: 0;
  img{
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
  }
}
</style>
